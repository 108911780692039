import "./chrome-gaia";

const SupportedKeyType = "KEY_TYPE_PASSWORD_PLAIN";

/** Describe add details for Google API. */
type GoogleAddDetails = {
    /** Token */
    token: unknown;
    /** The user */
    user: string;
    /** (hashed) password */
    passwordBytes: string;
    /** type of key. */
    keyType: string;
};

/** Describe completion details for google API. */
type GoogleCompleteDetails = {
    /** Token */
    token: unknown;
};

declare const google: {
    principal: {
        initialize: (callback: (keyType: string[]) => void) => void;
        add: (details: GoogleAddDetails, callback: () => void) => void;
        complete: (details: GoogleCompleteDetails, callback: () => void) => void;
    };
};

const Fields = {
    CBR_CHECK: "is_cbr",
    CBR_PASS: "cbr_pass",
} as const;

/** A class for handling google API for chromebook login. */
class ChromeBookRequest {
    /**
     * Initialize the chromebook request.
     */
    public init(): void {
        if (!(
            typeof Storage === "undefined" ||
            typeof localStorage === "undefined" ||
            localStorage === null
        )) {
            localStorage.removeItem(Fields.CBR_PASS);
        }
        if (!this.supported()) {
            return;
        }
        // WARNING: this check is not 100% foolproof. In theory someone could override the google.principal namespace or
        // the callback from it's device. Any check on client-side can be faked... Any way this is still a good way
        // to check if a certain flow can be enabled if you use it in combination with other checks
        if (this.readyToSend()) { // We already know it's a chromebook request
            return;
        }
        try {
            google.principal.initialize((keyType) => {
                if (!keyType.includes(SupportedKeyType)) {
                    const div = document.createElement("div");
                    div.style = "background-color: black; display: block; position: absolute; top: 0;";
                    div.innerText = "Unsupported keyType detected";
                    document.body.append(div);
                }

                this.set(true);
            });
        } catch (error) {
            this.set(false);
        }
    }

    /**
     * Is all the required features supported. If not returns false.
     *
     * @returns True when this functionality is supported.
     */
    public supported(): boolean {
        if (typeof Storage === "undefined" ||
            typeof localStorage === "undefined" ||
            localStorage === null ||
            typeof sessionStorage === "undefined" ||
            sessionStorage === null ||
            typeof google?.principal === "undefined"
        ) {
            return false;
        }
        // Request failed to initialize. Don't continue trying to complete the request.
        if (sessionStorage.getItem(Fields.CBR_CHECK) === "false") {
            sessionStorage.removeItem(Fields.CBR_PASS);
            return false;
        }
        return true;
    }

    /**
     * Has detected it is a chromebook and is ready to send data to the chromebook.
     *
     * @returns True when the Chromebook request is ready/initialized.
     */
    public readyToSend(): boolean {
        if (!this.supported()) {
            return false;
        }
        return !!sessionStorage.getItem(Fields.CBR_CHECK);
    }

    /**
     * Set property to identify as a chromebook request.
     *
     * @param isRequest true or false to indicate if this is a chromebook request.
     */
    private set(isRequest: boolean): void {
        if ((!this.supported()) && localStorage.getItem(Fields.CBR_CHECK) !== null) {
            return;
        }

        sessionStorage.setItem(Fields.CBR_CHECK, (`${isRequest}`));
    }
}

const cbr = new ChromeBookRequest();
cbr.init();
/** Manual functions to handle chromebook login. */
export class ChromeBookHandler {
    /**
     * Check if the current user is working on a chromebook login.
     *
     * @returns True when a Chromebook login is in session.
     */
    public static isChromeBookLogin(): boolean {
        // The CBR_CHECK has to be defined for it to be a chromebook login.
        return cbr.supported() && sessionStorage.getItem(Fields.CBR_CHECK) !== null;
    }

    /**
     * Set the password in local storage.
     *
     * @param passwordBytes The password to store.
     */
    public static setPassword(passwordBytes: string): void {
        if (!this.isChromeBookLogin()) {
            return;
        }
        sessionStorage.setItem(Fields.CBR_PASS, (passwordBytes));
    }

    /**
     * Set the chrome password for the user.
     *
     * @param token The relayState.
     * @param user The user identity.
     * @param passwordBytes The password as plain text.
     * @returns Promise
     */
    private static async sendPassword(token: string, user: string, passwordBytes: string): Promise<void> {
        return new Promise((resolve) => {
            google.principal.add({ token, user, passwordBytes, keyType: "KEY_TYPE_PASSWORD_PLAIN" }, resolve);
        });
    }

    /**
     * Complete chromebook login
     *
     * @param token The relayState.
     * @returns Promise
     */
    private static async login(token: string): Promise<void> {
        return new Promise((resolve) => {
            google.principal.complete({ token }, setTimeout.bind(undefined, resolve, 200));
        });
    }

    /**
     * Complete chromebook login when before saml is sent.
     *
     * @param relayState The relayState fo the saml request.
     * @param identity The identity of the saml request.
     * @returns Promise
     */
    public static async samlComplete(relayState: string, identity: string): Promise<void> {
        if (!cbr.readyToSend()) {
            return;
        }
        const password = sessionStorage.getItem(Fields.CBR_PASS);
        if (password) {
            await ChromeBookHandler.sendPassword(relayState, identity, password);
            sessionStorage.removeItem(Fields.CBR_PASS);
        }
        await ChromeBookHandler.login(relayState);
    }
}
